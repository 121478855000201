import React from "react"
import {graphql} from 'gatsby'
import styled from 'styled-components'
import Layout from "../components/layout"
import get from 'lodash/get'
import RichTextComponent from '../components/RichTextComponent'
import TwoLinesTitle from '../components/TwoLinesTitle'

const PageWrapper = styled.article`
  padding:0 3em 9em 3em;
  .description-wrapper{
    padding:0 1.5em;
    @media (min-width: 562px) {
      padding:0 0;
    }
  }
`

const HeroHeader = styled.header`
  height:33.333vh;
  position:relative;
  margin-bottom:${10 + 8.5}em;
  background:rgba(255,255,255,.125);

  .two-lines-title{
    position:absolute;
    bottom:0;
    left:3em;
    transform:translate3d(0,76%,0);
    z-index:1;
  }
`

const Page = (props) => {
  const edges = get(props.data, 'prismic.allPages.edges', [])
  const data = get(edges, '0.node')

  if (!data) {
    return null
  }

  const title = get(data, 'title[0].text  '),
  text = get(data, 'text')

  return(
    <Layout>
      <HeroHeader>
        <TwoLinesTitle
          titleLineOne="Cookie"
          titleLineTwo="Policy"
        />
      </HeroHeader>
      <PageWrapper>
        <RichTextComponent text={text} />
      </PageWrapper>
    </Layout>
  )
}

export const query = graphql`
  query Page($uid: String) {
    prismic {
      allPages(uid: $uid) {
        edges {
          node {
            title
            text
          }
        }
      }
    }
  }
`

export default Page

Page.query = query