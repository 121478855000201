import React from 'react'
import styled from 'styled-components'

const TwoLinesTitleWrapper = styled.header`
  width:100%;
`

const LineWrapper = styled.div`
  font-size:${props => props.theme.fontSizes.mobile.xl};
  @media (min-width: 562px) {
    font-size:${props => props.theme.fontSizes.tablet.xl};
  }
  @media (min-width: 1280px) {
    font-size:${props => props.theme.fontSizes.desktop.xl};
  }
  font-style:italic;
  text-transform:uppercase;
`

const TwoLinesTitle = (props) => {
  const{
    titleLineOne,
    titleLineTwo
  } = props
  return(
    <TwoLinesTitleWrapper
      className="two-lines-title"
    >
      <LineWrapper>
        {titleLineOne}
      </LineWrapper>
      <LineWrapper>
        {titleLineTwo}
      </LineWrapper>
    </TwoLinesTitleWrapper>
  )
}

export default TwoLinesTitle