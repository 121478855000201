import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../../utils/linkResolver';
const RichTextWrapper = styled.div`
  max-width:1024px;
  margin:0 auto;
   h1, h2,h3, h4, h5, strong{
    font-style:italic;
    text-transform:uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight:800;
  }
  h1{
    font-size:${props => props.theme.fontSizes.mobile.xl};
    @media (min-width: 562px) {
      font-size:${props => props.theme.fontSizes.tablet.xl};
    }
    @media (min-width: 1024px) {
      font-size:${props => props.theme.fontSizes.desktop.xl};
    }
  }
  h2{
    font-size:${props => props.theme.fontSizes.mobile.l};
    @media (min-width: 562px) {
      font-size:${props => props.theme.fontSizes.tablet.l};
    }
    @media (min-width: 1024px) {
      font-size:${props => props.theme.fontSizes.desktop.l};
    }
  }
  h3{
    font-size:${props => props.theme.fontSizes.mobile.m};
    @media (min-width: 562px) {
      font-size:${props => props.theme.fontSizes.tablet.m};
    }
    @media (min-width: 1024px) {
      font-size:${props => props.theme.fontSizes.desktop.m};
    }
  }
  h4{
    font-size:${props => props.theme.fontSizes.mobile.s};
    @media (min-width: 562px) {
      font-size:${props => props.theme.fontSizes.tablet.s};
    }
    @media (min-width: 1024px) {
      font-size:${props => props.theme.fontSizes.desktop.s};
    }
  }
  h1,h2,h3,h4{
    &:not(:first-child){
      margin-top:2rem;
    }
  }
  p,li{
    font-family:sans-serif;
    font-weight:500;
    line-height:1.5;
  }
  ul,ol{
    padding-left:1em;
  }
  li{
    list-style-type: circle;
  }
  * + *{
    margin-top:1rem;
  }
  a{
    color:${props => props.theme.colors.primary};
    text-decoration:underline;
  }
  &.single{
    margin-bottom:-3em;
  }
`

const RichTextComponent = (props) => {
  const {
    text
  } = props

  if(!text){
    return false
  }

  console.log(text.length)

  return(
    <RichTextWrapper className={text.length <= 1 && 'single'}>
      {RichText.render(text, linkResolver)}
    </RichTextWrapper>
  )
}

export default RichTextComponent